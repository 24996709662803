import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'



//列表页热门列表
export function zhaoshanglist(params) {
	return loginRequest({
		//更换成招商list的接口就可以了
		url: '/frontend/website/promotions/home-list',
		

		method: 'post',
		data: Qs.stringify(params)
	})
}


export function zhaoshanglisthot(params) {
	return loginRequest({
		//更换成招商list的接口就可以了
		url: '/frontend/website/promotions/hot',

		method: 'post',
		data: Qs.stringify(params)
	})
}










// //列表数据
export function list(params) {
	return loginRequest({
		url: '/frontend/website/promotions/list',
		method: 'post',
		data: Qs.stringify(params)
	})
}


//详情数据
export function detail(params) {
	return loginRequest({
		url: '/frontend/website/promotions/detail',
		method: 'post',
		data: Qs.stringify(params)
	})
}



//列表页热门列表
export function hotlist(params) {
	return loginRequest({
		url: '/frontend/website/promotions/hot',
		method: 'post',
		data: Qs.stringify(params)
	})
}