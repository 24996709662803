<template>
	<div class="aboutus">
		<site-header></site-header>
		<header-search></header-search>

		<nav-menu></nav-menu>
		<bread-line :showLevel="2"></bread-line>
		<div class="about">
			<div class="aboutcontent">
				<div class="left" v-if="showLeft">
					<bingomenu></bingomenu>
				</div>

				<div class="leftarea">
					<router-view />
				</div>

				<div v-if="!notShowRight" class="rightarea">
					<hotlist></hotlist>
					<zhibolist></zhibolist>
					<zhaoshanglist></zhaoshanglist>
				</div>

			</div>
		</div>
		
		<div style="width:100%;height:70px;background:rgba(240, 243, 248, 1)"></div>

		<index-footer></index-footer>
	</div>
</template>

<script>
import SiteHeader from '@/components/website/SiteHeader.vue'
import Bingomenu from '@/components/website/Bingomenu.vue'
import hotlist from '@/components/website/hotlist.vue'
import zhibolist from '@/components/website/zhibolist.vue'
import zhaoshanglist from '@/components/website/zhaoshanglist.vue'
import HeaderSearch from '@/components/website/HeaderSearch.vue'

import NavMenu from '@/components/website/NavMenu.vue'

import IndexFooter from '@/components/website/IndexFooter.vue'
import BreadLine from '@/components/website/BreadLine.vue'
export default {
	name: 'PcWebsiteIndex',
	components: { Bingomenu, SiteHeader, HeaderSearch, NavMenu, IndexFooter, BreadLine, hotlist, zhibolist, zhaoshanglist },

	data() {
		return {
			showLeft: false,
			notShowRight: false,
			list: [
				{
					name: '土拍简介',
					isSelect: false,
					path: 'about/intro'
				},
				{
					name: '土拍资源',
					isSelect: false,
					path: 'about/resource'
				},
				{
					name: '土拍优势',
					isSelect: false,
					path: 'about/advantage'
				}, {

					name: '专业服务',
					isSelect: false,
					path: 'about/service'
				}
			]
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	mounted() {

		this.setUweb()

		this.initlist()
	},

	watch: {
		$route: {
			handler(v) {
				this.setUweb()
				this.initlist()
			}
		}
	},

	methods: {
		setUweb(){
			const contenturl = this.$route.fullPath
			this.$uweb.trackPageview(contenturl)
		},

		initlist() {
			const name = this.$route.meta.title
			const path = this.site.path;

			let lastname = this.$route.path.split('/').pop()
			if ('detailhangpai' === this.$route.name) {
				lastname = this.$route.name
			}

			if ('detailhangpai' === this.$route.name || 'detailjujian' === this.$route.name || lastname === 'ziliao' || lastname === 'zhengce' || lastname === 'tudizhan' || lastname === 'hangpai' || lastname === 'fenghui' || lastname === 'bingo' || lastname === 'jjrzc' || lastname === 'addxuqiu' || lastname === 'addbingo' || lastname === 'addnong' || lastname === 'mine' || lastname === 'jjrinfo' || lastname === 'jujian') {
				this.notShowRight = true
			} else {
				this.notShowRight = false
			}



			if (lastname === 'addxuqiu' || lastname === 'addbingo' || lastname === 'addnong' || lastname === 'mine' || lastname === 'jjrinfo') {
				this.showLeft = true
			} else {
				this.showLeft = false
			}







			const list = this.list.map(item => {
				if (name === item.name) {
					item.isSelect = true
				} else {
					item.isSelect = false
				}

				item.url = '/' + path + '/' + item.path
				return item
			})

			this.list = list
		},
		tourl(path) {
			this.$router.push(path)
		}

	},
};
</script>

<style lang="scss" scoped>
.aboutus {
	width: 100%;
	// height: auto;
	height:100%;
	background: #F0F3F8;
}

.about {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	height:100%;

	.aboutmenu {
		.menuitem {
			width: 260px;
			margin-bottom: 4px;
			margin-right: 4px;
			height: 52px;
			background: #FFFFFF;
			//
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;

			line-height: 52px;
			color: #000000;
			text-align: left;
			padding-left: 18px;
			box-sizing: border-box;
			cursor: pointer;
		}

		.selectitem {
			background: #28A7E1;
			color: #FFFFFF;
			text-align: left;
		}

	}


}

.aboutcontent {
	// flex: 1;
	display: flex;

	// min-height:1290px;
		height: 100%;
	box-sizing: border-box;

	.leftarea {
		width: 100%;
		// min-height:518px;

		height: 100%;
		background:#fff;
	}

	.rightarea {
		margin-left: 14px;
		width: 356px;
		height:auto;
		min-height: 1290px;
	}
}
</style>